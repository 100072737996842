<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <app-form
                v-model="valueLocal"
                :schema="getSchema('SampleSets')"
                @cancel="dialog = false"
                @open="dialog = true"
                @input="onInput()"
                @delete="onInput()" />
        </v-dialog>
        <v-card
            style="display: inline-block; vertical-align: top"
            class="mr-4 mb-2"
            width="260px"
            color="#EAEAEA">
            <v-card-text class="pa-2">
                <div class="d-flex flex-nowrap">
                    <h3>
                        {{ valueLocal.name }}
                    </h3>
                    <v-icon class="ml-2" @click="dialog = true">
                        mdi-pencil
                    </v-icon>
                </div>
                <perfect-scrollbar
                    class="scroll-height"
                    :options="{ suppressScrollX: true }">
                    <draggable
                        class="sample-set-group"
                        :value="sampleSetTests"
                        group="sampleSet"
                        :disabled="!canEdit('SampleSetTests')"
                        @change="onCardChanged($event, valueLocal.id)">
                        <div v-for="(sampleSetTest, index) in sampleSetTests" :key="index">
                            <sample-set-test :sample-set-test="sampleSetTest" v-on="$listeners" />
                        </div>
                    </draggable>
                </perfect-scrollbar>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="canEdit('SampleSetTests')"
                    color="tertiary"
                    dark
                    @click="$emit('add', valueLocal.id)">
                    Add Sample Set Test
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

export default {
    components: {
        draggable,
        sampleSetTest: () => import("./SampleSetTest"),
        appForm: () => import("@/components/AppForm")
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        sampleSetTests: {
            type: Array,
            default: () => [],
        },
        filter: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isAdding: false,
            dialog: false
        };
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        onCardChanged(value, sampleSetId) {
            if (!this.isEmpty(value.moved)) {
                this.move(value.moved.element, sampleSetId);
                return;
            }
            if (!this.isEmpty(value.added)) {
                this.move(value.added.element, sampleSetId);
            }
        },

        async move(sampleSetTest, sampleSetId) {
            this.$emit("move", { sampleSetTest, sampleSetId });
        },

        onInput() {
            this.dialog = false;
            this.$emit("input");
        }
    },
};
</script>

<style scoped>
    .scroll-height {
        height: 60vh;
    }

    .sample-set-group {
        min-height: 100%;
    }
</style>
